import React from 'react';
import Icon from 'oc-core-components/src/Icon';
import { string, oneOfType, node } from 'prop-types';
import cn from 'classnames';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SearchSelectOption.module.scss';

const styles = stylesProxy(stylesObj, 'SearchSelectOption');

const SearchSelectOption = ({
  label,
  info,
  meta,
  icon,
  highlighted,
  onClick,
}) => (
  <div className={cn(styles.container, { [styles.highlighted]: highlighted })} onClick={onClick}>
    {
      icon && (
        <Icon icon={icon} size={18} stroke="none" className={styles.icon} />
      )
    }

    <div className={styles.content}>
      {
        label && (
          <div className={styles.label}>
            {label}
          </div>
        )
      }

      {
        info && (
          <div className={styles.info}>
            {info}
          </div>
        )
      }

      {
        meta && (
          <div className={styles.meta}>
            {meta}
          </div>
        )
      }
    </div>
  </div>
);

SearchSelectOption.propTypes = {
  label: string,
  info: string,
  meta: oneOfType([string, node]),
  icon: string,
};

export default SearchSelectOption;
