import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2ActiveProductCategory } from 'shared/v2/redux/meta/selectors';
import {
  useRouter,
} from 'oc-core-components/src/RouterProvider';

const SEARCH_BANNER_ID = 'navbar_search';

// TODO: i18n
const navbarSearchData = {
  navQuestions: {
    searchData: {
      filters: {
        kind: 'hw',
      },
    },
    label: 'Questions',
  },
  navDocuments: {
    searchData: {
      filters: {
        kind: 'default',

      },
    },
    label: 'Documents',
  },
  navTextbooks: {
    searchData: {
      filters: {
        kind: 'ts',

      },
    },
    label: 'Textbook Solutions',
  },
  navUsers: {
    searchData: {
      filters: {
        kind: 'user',

      },
    },
    label: 'Contributors',
  },
  // navBlogs: {
  //   searchData: {
  //     filters: {
  //       kind: 'blog',

  //     },
  //   },
  //   label: 'Blogs',
  // },
};

const navbarSearchTabs = Object.entries(navbarSearchData).map(([k, v]) => ({ ...v, uid: k }));

const productCategoryToNavTabsMap = {
  home: 'navDocuments',
  homeworkHelp: 'navQuestions',
  studyGuides: 'navDocuments',
  classNotes: 'navDocuments',
  textbookNotes: 'navDocuments',
  textbookSolutions: 'navTextbooks',
  boosterClasses: 'navQuestions',
  blog: 'navBlogs',
  studyResources: 'navDocuments',
  answer: 'navQuestions',
  contributors: 'navUsers',
};

const getActiveNavbarSearchTabId = ({ activeProductCategory, asPath }) => {
  if (activeProductCategory === 'studyResources') {
    if (asPath.match(/\/homework-help|\/booster-class/)) {
      return productCategoryToNavTabsMap.homeworkHelp;
    }
    if (asPath.match(/\/blog/)) {
      return productCategoryToNavTabsMap.blog;
    }
  }
  if (activeProductCategory === 'textbook_solutions') {
    return productCategoryToNavTabsMap.textbookSolutions;
  }
  if (activeProductCategory === 'contributors') {
    return productCategoryToNavTabsMap.contributors;
  }

  return productCategoryToNavTabsMap[activeProductCategory] || 'navDocuments';
};

const HIDDEN_SEARCH_REGEXP = /(\/search|\/study-guides\.|\/textbook-notes\.|\/class-notes\.)/;

const getIsSearchButtonHidden = ({ asPath }) => {
  if (asPath.match(HIDDEN_SEARCH_REGEXP)) {
    return true;
  }

  return false;
};

const escFunction = (event, callback) => {
  if (event.keyCode === 27) {
    callback && callback();
  }
};

const useNavbarSearch = ({ isDropdownOpen } = {}) => {
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(isDropdownOpen);
  const activeProductCategory = useSelector(getV2ActiveProductCategory);
  const { router: { asPath } } = useRouter();
  const isSearchButtonHidden = getIsSearchButtonHidden({ asPath });

  const initialNavbarSearchTabId = getActiveNavbarSearchTabId({ activeProductCategory, asPath });
  const [activeNavbarSearchTabId, setNavbarSearchTabId] = useState(initialNavbarSearchTabId);


  const setActiveNavbarSearchTabId = useCallback((item) => {
    setNavbarSearchTabId(item?.uid);
  }, []);

  const activeNavbarSearchTab = navbarSearchData[activeNavbarSearchTabId];

  const closeSearchDropdown = useCallback(() => {
    setIsSearchDropdownOpen(false);
  }, []);
  const openSearchDropdown = useCallback(() => {
    setIsSearchDropdownOpen(true);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', event => escFunction(event, closeSearchDropdown), false);

    return document.removeEventListener('keydown', event => escFunction(event, closeSearchDropdown), false);
  }, [closeSearchDropdown]);

  return ({
    isSearchButtonHidden,
    isSearchDropdownOpen,
    closeSearchDropdown,
    openSearchDropdown,
    setActiveNavbarSearchTabId,
    activeProductCategory,

    activeNavbarSearchTabId,
    activeNavbarSearchTab,
    navbarSearchTabs,
  });
};


export default useNavbarSearch;
