import cn from 'classnames';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import Icon from 'oc-core-components/src/Icon';
import { string } from 'prop-types';
import React from 'react';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Navbar');

const NavbarMenuIcon = ({
  className, fill, icon, stroke, styles,
}) => (
  <div className={cn(styles.navbar__menu__icon, className)}>
    <Icon
      fill={fill === 'none' ? undefined : fill}
      icon={icon}
      size={22}
      stroke={stroke}
      svgClassName={cn(fill === 'none' && styles['unfilled-svg'])}
    />
  </div>
);

NavbarMenuIcon.propTypes = {
  fill: string,
  icon: string.isRequired,
  stroke: string,
};

export default withAmpStyles(NavbarMenuIcon, s, stylesObj, ampStylesObj);
