
import { useSelector } from 'react-redux';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import usePrevious from 'helpers/hooks/usePrevious';
import { selectV2PageFilters, selectV2SearchPageCounters } from 'v2/redux/page/selectors';
import { selectV2FallbackSearchTopics } from 'v2/redux/page/homeworkHelp/selectors';
import {
  useRouter,
} from 'oc-core-components/src/RouterProvider';
import { getV2SearchQuery } from 'v2/redux/meta/selectors';

import {
  isEmpty, isObject, isFunction, isNil,
} from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';

import {
  ROUTES,
} from 'helpers/constants';

import {
  fallbackOrderValue, fallbackKind, QUERY_KEYS, USER_CATEGORIES, MOBILE_USER_CATEGORIES, filtersData, isDocumentKind, isUserKind, formatCount, getKindByMaterialType,
} from 'oc_components/src/pages/Search/utils';
import { useTranslation } from 'config/i18n';


function useSearch({
  baseUrl = ROUTES.search,
  filters: customFilters,
  standalone,
} = {}) {
  const { pushRoute } = useRouter();
  const { t } = useTranslation(['common', 'search']);
  const isMobile = useMobile();
  const userCategoriesMap = isMobile ? MOBILE_USER_CATEGORIES : USER_CATEGORIES;

  const searchQuery = useSelector(getV2SearchQuery);
  const pageFilters = useSelector(selectV2PageFilters);
  const counters = useSelector(selectV2SearchPageCounters);
  const fallbackTopics = useSelector(selectV2FallbackSearchTopics) || [];

  const {
    kind,
    materialType,
    order,
    courseId,
    schoolId,
    examType,
    lectureNo,
    semester,
    ntCourses,
    subject,
    ...restFilters
  } = pageFilters || {};

  const kindValue = useMemo(() => {
    if (materialType) {
      return getKindByMaterialType(materialType);
    }

    return kind;
  }, [kind, materialType]);


  const currentFilters = useMemo(() => ({
    query: searchQuery,
    tbQuery: searchQuery,
    order: fallbackOrderValue(order),
    kind: fallbackKind(kindValue),
    courseId,
    schoolId,
    examType,
    lectureNo,
    ntCourses,
    subject,
    topics: subject, // temp solution; TODO: rename topics field
    term: semester,
    ...restFilters,
    ...customFilters,
  }), [order, subject, courseId, customFilters, schoolId, examType, lectureNo, semester, kindValue, searchQuery, restFilters, ntCourses]);

  const isDocumentSearch = isDocumentKind(currentFilters.kind);
  const isUserSearch = isUserKind(currentFilters.kind);
  const showUserCategories = isUserSearch;

  // const topicItems = (counters?.topics || fallbackTopics);

  // const hasTopics = !!topicItems?.length;
  const currentType = currentFilters?.type;

  const [activeUserCategory, setActiveUserCategory] = useState(() => {
    if (currentType === 'documents') {
      return userCategoriesMap.course;
    }

    if (currentType === 'answers') {
      return userCategoriesMap.subject;
    }

    return userCategoriesMap.username;
  });


  const setFilter = (filterName, filterValue, withRedirect, redirectParams) => {
    let updates = {};

    if (isObject(filterName)) {
      updates = filterName;
    } else if (!isNil(filterName)) {
      updates = { [filterName]: filterValue };
    }
    if (!isEmpty(updates)) {
      if (withRedirect) {
        const urlParams = { ...updates, ...redirectParams };

        const activeUserCategoryValue = activeUserCategory?.value;

        if (activeUserCategoryValue === userCategoriesMap.course.value) {
          urlParams.type = 'documents';
        } else if (activeUserCategoryValue === userCategoriesMap.subject.value) {
          urlParams.type = 'answers';
        }

        window.location.assign(buildUrl(baseUrl, urlParams));
      } else {
        pushRoute({
          queryUpdates: updates,
        });
      }
    }
  };

  const removeFilter = (filterName) => {
    const updates = {};

    if (Array.isArray(filterName)) {
      filterName.forEach((name) => {
        updates[name] = undefined;
      });
    } else if (!isNil(filterName)) {
      updates[filterName] = undefined;
    }

    if (!isEmpty(updates)) {
      setFilter(updates);
    }
  };

  const setOrder = o => setFilter(QUERY_KEYS.order, o);

  const userCategories = useMemo(() => {
    const res = [
      userCategoriesMap.username,
      userCategoriesMap.course,
      userCategoriesMap.subject,
    ];

    // if (!currentType || currentType === 'documents' || standalone) {
    //   res.push(userCategoriesMap.course);
    // }

    // if (!currentType || currentType === 'answers' || standalone) {
    //   res.push(userCategoriesMap.subject);
    // }
    return res;
  }, [currentType, standalone, userCategoriesMap]);


  const onUserCategoryChange = useCallback((item) => {
    setActiveUserCategory(item);
  }, []);

  const activeUserCategoryValue = activeUserCategory?.value;
  const prevCurrentType = usePrevious(currentType);

  useEffect(() => {
    if (prevCurrentType !== currentType) {
      if (currentType === 'answers' && activeUserCategoryValue !== userCategoriesMap.subject.value) {
        setActiveUserCategory(userCategoriesMap.subject);
      }

      if (currentType === 'documents' && activeUserCategoryValue !== userCategoriesMap.course.value) {
        setActiveUserCategory(userCategoriesMap.course);
      }

      if (!currentType) {
        setActiveUserCategory(userCategoriesMap.username);
      }
    }
  }, [currentType, prevCurrentType, activeUserCategoryValue, userCategoriesMap]);


  const sideFiltersData = useMemo(() => {
    if (!counters) return undefined;

    // counters.ntCourses = [
    //   {
    //     label: 'Verified notes only',
    //     value: 'true',
    //     count: 10,
    //   },
    // ];

    const result = Object.entries(filtersData).reduce(
      (acc, [filterType, data]) => {
        const filterCounter = counters[filterType];

        if (!filterCounter || isEmpty(filterCounter) || !Array.isArray(filterCounter)) {
          return acc;
        }

        const {
          titleI18n,
          itemsI18n,
          paramKey,
          icon,
          iconTooltipText,
          ...rest
        } = data;

        const res = {
          title: titleI18n ? t(titleI18n) : undefined,
          items: filterCounter.map(({ label, value, count }) => ({
            active: currentFilters[filterType] === value,
            label: isFunction(itemsI18n) ? itemsI18n(label, t) : label,
            value,
            count: formatCount(count),
            paramKey,
          })),
          icon,
          iconTooltipText,
          paramKey,
          ...rest,
        };

        return {
          ...acc,
          [filterType]: res,
        };
      },
      {},
    );

    return result;
  }, [counters, currentFilters]);

  const activeSideFilters = useMemo(() => {
    if (!isObject(sideFiltersData)) return undefined;

    return Object.values(sideFiltersData).reduce((acc, { items }) => [...acc, ...items.filter(i => i.active)], []);
  }, [sideFiltersData]);

  return {
    filters: currentFilters,
    setFilter,
    removeFilter,
    setOrder,
    isDocumentSearch,
    sideFiltersData,
    activeSideFilters,

    showUserCategories,
    userCategories,
    activeUserCategory,
    onUserCategoryChange,
    fallbackTopics,
  };
}

export default useSearch;
