import { stylesProxy } from 'helpers/css';
import { getDynamicImportProps } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import dynamic from 'next/dynamic';
import Link from 'oc-core-components/src/Link';
import { bool, func, string } from 'prop-types';
import React from 'react';
import NavbarMenuIcon from 'shared/components/Navbar/NavbarMenuIcon';
import stylesObj from './BottomNavbar.module.scss';
import ampStylesObj from './BottomNavbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'BottomNavbar');

const dynamicProps = getDynamicImportProps();

const MessageCounter = dynamic(
  () => import(
      /* webpackChunkName: "chunk_MessageCounter" */ 'oc-core-components/src/Notifications/NotificationsCounterContainer'
    ),
  dynamicProps,
);

const BottomNavbarMenuItem = ({
  href,
  onClick,
  showNotifications,
  nofollow,
  styles,
  label,
  description,
  icon,
  stroke,
}) => (
  <li className={styles.dd__item}>
    <Link
      nofollow={nofollow}
      className={styles.dd__link}
      href={href}
      onClick={onClick}
    >
      {icon && <NavbarMenuIcon className={styles.dd__item__icon} stroke={stroke} icon={icon} />}

      <div className={styles.dd__item__text}>
        <div className={styles.dd__item__text__title}>{label}</div>
        <div className={styles.dd__item__text__description}>{description}</div>
      </div>

      {showNotifications && (
        <MessageCounter className={styles.dd__count} empty />
      )}
    </Link>
  </li>
);

BottomNavbarMenuItem.propTypes = {
  href: string,
  showNotifications: bool,
  onClick: func,
  nofollow: bool,
  label: string,
  description: string,
  icon: string,
  stroke: string,
};

export default withAmpStyles(BottomNavbarMenuItem, s, stylesObj, ampStylesObj);
