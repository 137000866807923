import cn from 'classnames';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import React from 'react';
import { LinkListType, uidType } from 'types';
import stylesObj from './BottomNavbar.module.scss';
import ampStylesObj from './BottomNavbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'BottomNavbar');

const BottomNavbarDesktop = ({
  bottomToolbarBeforeContent,
  className,
  innerClassName,
  styles,
  ...restProps
}) => {
  const isMobile = useMobile();

  if (!bottomToolbarBeforeContent || isMobile) {
    return null;
  }

  return (
    <div className={cn(className, styles.container)} {...restProps}>
      <div className={cn(styles.inner, innerClassName)}>
        {bottomToolbarBeforeContent}
      </div>
    </div>
  );
};

BottomNavbarDesktop.propTypes = {
  bottomToolbarBeforeContent: node,
  innerClassName: string,
};

export default withAmpStyles(BottomNavbarDesktop, s, stylesObj, ampStylesObj);
