import { Enhancer } from 'oc-core-components/src/Formik';
import { itemToValue } from 'oc-core-components/src/Autocomplete/utils';
import { isFunction, isObject } from 'helpers/utils';
import { reUsername } from 'shared/helpers/validationSchema';
import {
  INCORRECT_USERNAME,
} from 'helpers/validationMessages';

import fieldsMap from 'oc_components/src/pages/SearchNew/SearchGroup/fields';
import SearchGroup from 'oc_components/src/pages/SearchNew/SearchGroup';

// import schema from './schema';

const initialData = {
  course_id: null,
  school_id: null,
  tb_query: null,
  query: '',
};

export const formikEnhancer = Enhancer('SearchForm', {}, {
  defaultInitialData: initialData,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const {
      notificationContext,
      onSubmit,
      filters,
      kind,
    } = props;

    try {
      if (!Array.isArray(filters)) {
        return null;
      }

      const queryUpdates = filters.reduce((acc, filter) => {
        const name = fieldsMap[filter]?.name;


        if (!name) return acc;

        const val = values[name];

        return {
          ...acc,
          [name]: isObject(val) ? itemToValue(val) : val,
        };
      }, {});

      if (kind) {
        queryUpdates.kind = kind;
      }

      // For some reason schema.js doesn't trigger validation message for userq[with nickname type]
      // that's why we have to perform a more strict validation here before request
      if (queryUpdates.userq && !queryUpdates.userq.match(reUsername)) {
        setErrors({ userq: INCORRECT_USERNAME });
        notificationContext?.addErrorNotification(INCORRECT_USERNAME);
        return;
      }

      if (isFunction(onSubmit)) {
        // Go to add courses
        onSubmit(queryUpdates);
      }
    } catch (e) {
      notificationContext?.addErrorNotification();
      setSubmitting(false);
      console.error(e);
    }
  },
  enableReinitialize: true,
});

const EnhancedForm = formikEnhancer(SearchGroup);

export default EnhancedForm;
