import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import cn from 'classnames';
import { HEADER_ID } from 'helpers/constants';
import { stylesProxy } from 'helpers/css';
import { isFunction } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import { useRouter } from 'oc-core-components/src/RouterProvider';
import Row from 'oc-core-components/src/Row';
import {
  arrayOf, bool, func, node, shape, string,
} from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { BottomNavbarItemPrefixes } from 'shared/components/BottomNavbar/constants';
import { LinkListType, uidType } from 'types';
import Sticky from 'oc-core-components/src/Sticky';
import stylesObj from './BottomNavbar.module.scss';
import ampStylesObj from './BottomNavbar.module.scss?amp&type=resolve';
import Item from './BottomNavbarItem';
import MenuItem from './BottomNavbarMenuItem';

const s = stylesProxy(stylesObj, 'BottomNavbar');

const BottomNavbar = ({
  bottomToolbarBeforeContent,
  userMenu,
  items,
  logoUrl,
  isLoggedIn,
  onItemClick,
  className,
  linkClassName,
  labelClassName,
  innerClassName,
  styles,
  ...restProps
}) => {
  const containerRef = useRef();
  const {
    router: { asPath },
  } = useRouter();
  const [showOverlay, setShowOverlay] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  // Workaround so that the navigation bar is not on top of the overlay.
  useEffect(() => {
    if (containerRef.current) {
      if (showOverlay) {
        disableBodyScroll(containerRef.current);
      } else {
        enableBodyScroll(containerRef.current);
      }
    }


    const header = document.getElementById(HEADER_ID);
    if (header) {
      header.style.zIndex = showOverlay ? '999' : null;
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showOverlay]);

  const toggleMenu = (uid) => {
    const currentMenu = openMenu === uid ? null : uid;

    setOpenMenu(currentMenu);
    setShowOverlay(!!currentMenu);
  };

  const handleItemClick = (openedMenuId, itemUid, callback) => (e) => {
    toggleMenu(openedMenuId);
    onItemClick(itemUid, e);

    if (isFunction(callback)) {
      callback(itemUid);
    }
  };

  const overlayClickHandler = (callback) => {
    setOpenMenu(null);
    setShowOverlay(false);

    if (isFunction(callback)) {
      callback();
    }
  };

  let activeUid = 'home';
  items?.forEach(({ uid }) => {
    const prefix = BottomNavbarItemPrefixes[uid];

    if (asPath.startsWith(prefix)) {
      activeUid = uid;
    }
  });

  return (
    <Sticky bottomPos>
      <div
        // className={cn(className, styles.container)}
        className={className}
        ref={containerRef}
        {...restProps}
      >
        {showOverlay && (
          // eslint-disable-next-line
          <div className={styles.overlay} onClick={overlayClickHandler} />
        )}

        <div className={cn(styles.inner, innerClassName)}>
          {bottomToolbarBeforeContent}

          {isLoggedIn && (
            <Row className={cn('u-justify-content-around', styles.row)}>
              {items?.map(
                ({
                  uid,
                  label,
                  url,
                  items: subMenu,
                  icon,
                  iconStroke,
                  round,
                  onClick,
                  nofollow,
                  title,
                  props,
                }) => (
                  <Item
                    icon={icon}
                    iconStroke={iconStroke}
                    label={label}
                    key={uid}
                    uid={uid}
                    href={url}
                    isOpen={openMenu === uid}
                    openMenu={!!openMenu}
                    round={round}
                    withNotifications={isLoggedIn && uid === 'notifications'}
                    onClick={handleItemClick(subMenu ? uid : null, uid, onClick)}
                    nofollow={nofollow}
                    linkClassName={cn(
                      linkClassName,
                      (uid === activeUid || uid === 'add') && styles['is-active'],
                    )}
                    labelClassName={labelClassName}
                    title={title}
                    {...props}
                  >
                    {subMenu?.map(el => (
                      <MenuItem
                        label={el.label}
                        description={el.description}
                        icon={el.icon}
                        stroke={el.stroke}
                        key={el.uid}
                        href={el.url}
                        onClick={handleItemClick(null, el.uid, el.onClick)}
                        showNotifications={
                          isLoggedIn && el.uid === 'notifications'
                        }
                        nofollow={el.nofollow}
                      />
                    ))}
                  </Item>
                )
              )}
            </Row>
          )}
        </div>

      </div>
    </Sticky>
  );
};

BottomNavbar.propTypes = {
  bottomToolbarBeforeContent: node,
  items: arrayOf(
    shape({
      uid: uidType,
      label: string,
      url: string,
      items: LinkListType,
    }),
  ),
  logoUrl: string,
  isLoggedIn: bool,
  onItemClick: func,
  linkClassName: string,
  labelClassName: string,
  innerClassName: string,
  router: shape(),
};

BottomNavbar.defaultProps = {
  items: [],
  onItemClick: () => {},
};

export default withAmpStyles(BottomNavbar, s, stylesObj, ampStylesObj);
