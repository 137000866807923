import { useTranslation } from 'config/i18n';
import { stylesProxy } from 'helpers/css';
import getAuthLink from 'helpers/utils/getAuthLink';
import withAmpStyles from 'helpers/withAmpStyles';
import Button from 'oc-core-components/src/Button';

import { shape } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import stylesObj from './BottomNavbar.module.scss';
import ampStylesObj from './BottomNavbar.module.scss?amp&type=resolve';
import BottomNavbarContainer from './BottomNavbarContainer';

const s = stylesProxy(stylesObj, 'BottomNavbar');

const BottomNavbarLogin = ({ authParams, hideLoggedOut, styles, ...restProps }) => {
  const isLoggedIn = useSelector(getV2IsLoggedIn);
  const { t } = useTranslation('navbar');

  const signupUrl = getAuthLink({ intent: false, ...authParams });

  if (isLoggedIn) {
    return <BottomNavbarContainer {...restProps} />;
  }

  if(hideLoggedOut) {
    return null;
  }

  return (
    <div className={styles['bottom-navbar-login']}>
      <Button className="u-d-block" href={signupUrl} roundBorders size="lg">
        {t('navbar:navbar.buttonLogInOrSignUp')}
      </Button>
    </div>
  );
};

BottomNavbarLogin.propTypes = {
  authParams: shape(),
};

BottomNavbarLogin.defaultProps = {
  authParams: {},
};

export default withAmpStyles(BottomNavbarLogin, s, stylesObj, ampStylesObj);
