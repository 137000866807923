import {
  useEffect,
} from 'react';
import usePrevious from 'helpers/hooks/usePrevious';
import fields from './fields';
import isEqual from 'react-fast-compare';


const hasChanged = (name, values, prevValues) => !isEqual(prevValues[name], values[name]);

export default function useResetDependencies({
  values,
  filters,
  setFieldValue,
}) {

  const prevValues = usePrevious(values);

  useEffect(() => {
    try {

      // reset next fields, if the current field was updated
      if((filters?.length > 0) && values && prevValues && !isEqual(values, prevValues)) {
        const names = filters.map(filter => fields[filter].name);


        for (let i = 0; i < (names.length - 1); i++) {
          const name = names[i];

          if(hasChanged(name, values, prevValues)) {
            const affectedNames = names.slice(i + 1);

            affectedNames.forEach((n) => {
              if(!hasChanged(n, values, prevValues)) {
                setFieldValue(n, null);
              }
            });

            break;
          }
        }
      }

    } catch (e) {
      console.error(e);
    }
  }, [values, prevValues, filters])

}
