import React, { useCallback, useState } from 'react';
import { bool, node, oneOf } from 'prop-types';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SearchSelect.module.scss';

const styles = stylesProxy(stylesObj, 'SearchSelect');

const SearchSelect = ({
  label,
  placeholder,
  className,
  onFocus,
  onBlur,
  items,
  renderItem,
  component: Component,
  selectable,
  inputProps,
  showClearButton,
  onClearButtonClick,
  resetFields,
  defaultHighlightedIndex,
  ...restProps
}) => {
  const [focused, setFocused] = useState();

  const onFocusHandler = useCallback(() => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, []);

  const onBlurHandler = useCallback(() => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  }, []);

  const isSelect = restProps.select;

  return (restProps.type === 'text') ? (
    <div className={cn(styles.container, { [styles.selectable]: selectable, [styles.active]: focused })}>
      <Component
        className={styles.input}
        inputClassName={styles.input__field}
        wrapperClassName={cn(styles.wrapper, className)}

        placeholder={placeholder}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}

        renderBeforeField={label && <div className={styles.label}>{label}</div>}
        fieldContainerClassName={styles['field-wrapper']}

        {...inputProps}
        {...restProps}
      />
    </div>
  ) : (
    <Component
      disableFiltering
      inputClassName={cn(styles.input, { [styles.select__input]: isSelect })}
      inputFieldClassName={cn(styles.input__field)}
      wrapperClassName={cn(styles.wrapper, className)}
      placeholder={placeholder}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onCancel={onClearButtonClick}
      resetFields={resetFields}
      inputWrapperClassName={cn({ [styles.select__wrapper]: isSelect })}
      className={cn(styles.container, { [styles.selectable]: selectable, [styles.active]: focused })}
      menuClassName={styles.menu}
      renderItem={renderItem}
      itemClassName={styles.dd__item}
      items={items}
      // showClearOnFocus
      disableLabel
      showClearButton={showClearButton}
      defaultHighlightedIndex={defaultHighlightedIndex}
      inputProps={{
        renderBeforeField: label && <div className={styles.label}>{label}</div>,
        fieldContainerClassName: styles['field-wrapper'],
        ...inputProps,
      }}
      {...restProps}
    />
  );
};

SearchSelect.propTypes = {
  label: node,
  placeholder: node,
  active: bool,
  // type: oneOf(['school', 'course', 'question']),
};

export default SearchSelect;
