import React, { useEffect, useState, useCallback, useRef } from 'react';
import ContentSection from 'oc-core-components/src/ContentSection';
import { isFunction, isNil } from 'helpers/utils';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Container from 'oc-core-components/src/Container';
import Link from 'oc-core-components/src/Link';
import { SimpleTab } from 'oc-core-components/src/Tabs';
import cn from 'classnames';
import { func, shape, arrayOf, bool, string } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarSearchTabs.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarSearchTabs');

const NavbarSearchTabs = ({
  className,
  tabs = [],
  onTabClick,
  defaultActiveTab,
  hideArrows,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0]?.uid);
  const containerRef = useRef();
  const activeItemRef = useRef();

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  useEffect(() => {
    try {
      if (!activeItemRef?.current || !containerRef?.current) {
        console.error('Incorrect refs in NavbarSearch tabs component');

        return;
      }

      const { offsetLeft } = activeItemRef.current;
      const { clientWidth } = containerRef.current;
      const { offsetWidth } = activeItemRef.current;

      const scrollOffset = offsetLeft - ((clientWidth - offsetWidth) / 2);
      containerRef.current.scrollLeft = scrollOffset;
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onTabClickHandler = useCallback(async (item) => {
    await setActiveTab(item?.uid);

    if (isFunction(onTabClick)) {
      await onTabClick(item);
    }
  }, [onTabClick]);

  const tabsArr = Object.values(tabs);

  return (
    <ul tag="ul" className={cn(styles.container, className)} ref={containerRef}>
      {
        (tabsArr?.length > 0) && tabsArr.map((item) => {
          const isActiveTab = activeTab === item.uid;

          return (
            <li
              className={styles.tab}
              key={item.uid}
              ref={isActiveTab ? activeItemRef : null}
            >
              <Link
                // isActive={activeTab === item.uid}
                onClick={() => onTabClickHandler(item)}
                key={item.uid}
                className={cn(styles.tab__link, { [styles['tab__link--active']]: isActiveTab })}
              >
                {item.label}
                {
                  !isNil(item.count) && (
                    <span className={cn(styles.count, { [styles['count--active']]: isActiveTab })}>{item.count}</span>
                  )
                }
                {
                  isActiveTab && (<div className={styles.item__underline} />)
                }
                {
                  !hideArrows && isActiveTab && (
                    <div className={styles.item__arrow_cover}>
                      <div className={cn(styles.item__underline_arrow)} />
                    </div>
                  )
                }
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
};

NavbarSearchTabs.propTypes = {
  tabs: arrayOf(shape({
    searchData: shape(),
    label: string,
    uid: string,
  })),
  onTabClick: func,
  defaultActiveTab: string,
  hideArrows: bool,
};

export default NavbarSearchTabs;
