import React, { PureComponent } from 'react';
import raf from 'raf'; // request animation frame polyfill
import { CSSTransition } from 'react-transition-group';
import { withMobileMQ } from 'oc-core-components/src/MediaQuery';


import {
  bool, node, oneOfType, func, number, string, shape,
} from 'prop-types';
import cn from 'classnames';
import { optimizedResize, isFunction } from 'helpers/utils';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Sticky.module.scss';
import ampStylesObj from './Sticky.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Sticky');

class Sticky extends PureComponent {
  constructor(props) {
    super(props);
    this.optimizedResize = optimizedResize();
    this.container = React.createRef();
    this.inner = React.createRef();
    this.innerHeight = 0;

    this.state = {
      hidden: false,
      loaded: props.ssrFixed,
    };
  }

  componentDidMount() {
    if (this.optimizedResize) {
      this.optimizedResize.add(this.resizeHanlder);
    }
    this.resizeHanlder();
    this.setBreakpoint();
    this.initScroll();

    this.setState({
      loaded: true,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      matches,
    } = this.props;

    if (!matches && prevProps.matches !== matches && this.state.hidden) {
      this.toggleHiddenState(false);
    }
  }

  componentWillUnmount() {
    if (this.optimizedResize) {
      this.optimizedResize.clear();
    }
    this.removeScroll();
  }

  setScrollParams = () => {
    const inner = this.inner?.current;
    const scrollY = this.getScrollY();

    this.scrollUp = scrollY < this.scrollPos;
    // saves the new position for iteration.
    this.scrollPos = scrollY;

    if (!this.innerHeight && inner) {
      this.innerHeight = window.getComputedStyle(inner).height;
    }
  }

  getScrollY = () => {
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset;
    } if (window.scrollTop !== undefined) {
      return window.scrollTop;
    }
    return (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }

  initScroll = () => {
    document.addEventListener('scroll', this.handleScroll);
  }

  removeScroll = () => {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.scrollTicking) {
      this.scrollTicking = true;
      // use request animation frame to optimize scroll handlers
      raf(this.update);
    }
  }

  resizeHanlder = () => {
    if (!this.resizeTicking) {
      this.resizeTicking = true;
      raf(this.setHeightOffset);
    }
  }

  setHeightOffset = () => {
    const { reserveSpace } = this.props;
    const inner = this.inner?.current;
    const container = this.container?.current;

    if (inner && container) {
      const innerHeight = window.getComputedStyle(inner).height;

      container.style.height = reserveSpace ? innerHeight : '';
      this.innerHeight = innerHeight;
    }
    this.resizeTicking = false;
  }

  update = () => {
    const { hideOnScroll: customHideOnScroll, matches, hideOnTop } = this.props;
    const hideOnScroll = (typeof customHideOnScroll === 'boolean') ? customHideOnScroll : matches;

    this.setScrollParams();

    const isOnTop = this.isOnTop();

    if (hideOnScroll) {
      if (isOnTop) {
        if (hideOnTop && !this.state.hidden) {
          this.toggleHiddenState(true);
        }
      } else if (this.state.hidden === this.scrollUp) {
        // hide the container when scrolling down
        this.toggleHiddenState(!this.scrollUp);
      }
    } else if (hideOnTop && (this.state.hidden !== isOnTop)) {
      // hide the container in the top part of the page
      this.toggleHiddenState(isOnTop);
    }

    this.scrollTicking = false;
  }

  toggleHiddenState = (toggle) => {
    this.setState({ hidden: toggle });
    // if (!this.toggleTicking) {
    //   this.toggleTicking = true;
    //   raf(() => {
    //     this.setState({ hidden: toggle });
    //     this.toggleTicking = false;
    //   });
    // }
  }

  isOnTop = () => {
    const { margin } = this.props;
    let breakpoint = this.breakpoint + margin;
    if (breakpoint < 0) breakpoint = 0;
    return this.getScrollY() < Math.max(parseFloat(this.innerHeight), breakpoint);
  }

  setBreakpoint = () => {
    const { stickyBreakpoint } = this.props;

    // if stickyBreakpoint is a id of the element on the page => calculate its top position relative to the page top border
    if (typeof stickyBreakpoint === 'string') {
      const breakpointEl = document.getElementById(stickyBreakpoint);

      if (breakpointEl) {
        this.breakpoint = breakpointEl.getBoundingClientRect().top;
      } else {
        // console.error('Incorrect header id: ', stickyBreakpoint);
        this.breakpoint = 0;
      }
    } else if (typeof stickyBreakpoint === 'number') {
      this.breakpoint = stickyBreakpoint;
    } else {
      this.breakpoint = 0;
    }
  }


  render() {
    const {
      children,
      ref: customRef,
      innerClassName,
      style,
      stickyBreakpoint,
      animationDuraion,
      hideOnScroll,
      margin,
      hideOnTop,
      hideOnMount,
      reserveSpace,
      transitionProps,
      matches,
      ssrFixed,
      styles,
      bottomPos,
      isAmp,
      ...restProps
    } = this.props;

    const { hidden, loaded } = this.state;

    // let innerStyle = style;

    // if (animationDuraion) {
    //   const transitionDuration = `${animationDuraion}ms`;
    //   innerStyle = {
    //     transitionDuration,
    //     WebkitTransitionDuration: transitionDuration,
    //     ...style,
    //   };
    // }

    return (
      <div ref={this.container} {...restProps}>
        <CSSTransition
          in={hidden}
          timeout={animationDuraion}
          classNames={cn({ 'navbar-slide-top': !bottomPos, 'navbar-slide-bottom': bottomPos })}
          {...transitionProps}
        >
          {() => (
            <div
              // use css transition class to hide the navbar on mount
              className={cn(
                styles.container,
                { [styles.bottom]: bottomPos },
                innerClassName,
                {
                  [styles[`navbar-slide-${bottomPos ? 'bottom' : 'top'}-enter-done`]]: hideOnTop && hideOnMount,
                  [styles['is-static']]: !loaded,
                  [styles['is-amp']]: isAmp,
                },
              )}
              style={style}
              ref={this.inner}
            >
              {(isFunction(children) ? children() : children)}
            </div>

          )}
        </CSSTransition>
      </div>

    );
  }
}


Sticky.propTypes = {
  /** Disable sticky mode */

  children: oneOfType([node, func]),
  stickyBreakpoint: oneOfType([number, string]),
  animationDuraion: number, // when changing animationDuraion, we should also update the css duration value
  hideOnScroll: bool,
  margin: number,
  reserveSpace: bool,
  style: shape(),
  transitionProps: shape(),
  // set fixed when ssr
  ssrFixed: bool,
  matches: bool,
  hideOnTop: bool,
  innerClassName: string,
  hideOnMount: bool,
  bottomPos: bool,
  ref: shape(),
};

Sticky.defaultProps = {
  reserveSpace: true,
  stickyBreakpoint: 0,
  animationDuraion: 250,
  margin: 0,
};

export default withMobileMQ(withAmpStyles(Sticky, s, stylesObj, ampStylesObj));
