import React, {
  useState, createRef, useRef, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import cn from 'classnames';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { createItem } from 'oc-core-components/src/Autocomplete/utils';
import Autocomplete from 'oc-core-components/src/Autocomplete';
import SearchSelectOption from 'oc_components/src/pages/SearchNew/SearchSelectOption';
// import Button from 'oc-core-components/src/Button';
import { Submit, Field } from 'oc-core-components/src/Formik';
import { Form } from 'formik';
import usePrevious from 'helpers/hooks/usePrevious';

import SearchSelect from './components/SearchSelect';
// import SearchSelectNew from './components/SearchSelectNew';
import useResetDependencies from './useResetDependencies';
import fields from './fields';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SearchGroup.module.scss';

const styles = stylesProxy(stylesObj, 'SearchGroup');


const SearchGroup = ({
  className,
  filters,
  onChange,
  onClearButtonClick,
  values,
  initialData,
  handleSubmit,
  setValues,
  setFieldValue,
  standalone,
  kind,
  submitText,
  submitProps,
  submitForm,

  showUserCategories,
  userCategories,
  activeUserCategory,
  onUserCategoryChange,
  ...restProps
}) => {
  const currentPage = useSelector(getV2CurrentPage);
  const refs = useRef(new Array(filters?.length).fill(null).map(() => createRef()));
  const [focused, setFocused] = useState();
  const isMobile = useMobile();
  const previousKind = usePrevious(kind);
  const kindIsChanged = kind !== previousKind;
  const submitLabel = submitText || 'Search';

  useEffect(() => {
    const refsArr = refs?.current;
    if (!refsArr?.length) {
      return;
    }

    if (isMobile && currentPage !== 'search') {
      return;
    }


    const firstRef = refsArr[0]?.current;
    if (!firstRef) return;

    const currentInput = refsArr.find(el => !initialData[el?.current?.name]);

    if (currentInput?.current) {
      currentInput?.current.focus();
    }
  }, [initialData, kindIsChanged, isMobile, currentPage]);

  useResetDependencies({
    values,
    setFieldValue,
    filters,
  });

  const stateHandlers = {
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
  };

  const onSubmit = useCallback(() => {
    try {
      const firstField = fields[filters[0]];

      const firstValue = values[firstField.name];

      if (!firstValue) {
        refs?.current[0]?.current?.focus();
      } else {
        handleSubmit();
      }
    } catch (e) {
      console.error(e);
    }
  }, [values, refs, handleSubmit, filters]);


  const onSelect = useCallback((item, indx, cb) => {
    refs?.current[indx]?.current?.blur();

    const refsArr = refs?.current;

    if (!refsArr?.length) {
      return;
    }

    if (indx === (refsArr.length - 1)) {
      setTimeout(() => onSubmit(), 200);
    }

    if (cb) {
      cb(item, indx, { handleSubmit });
    }
  }, [refs, handleSubmit, onSubmit]);

  const onEnterSelect = useCallback((item, indx, cb) => {
    try {
      const refsArr = refs?.current;

      if (!refsArr?.length) {
        return;
      }

      if (indx < (refsArr.length - 1)) {
        setTimeout(() => refsArr[indx + 1]?.current?.focus(), 150);
      } else {
        setTimeout(() => onSubmit(), 300);
      }

      if (cb) {
        cb(item, indx, { handleSubmit });
      }
    } catch (e) {
      console.log(e);
    }
  }, [refs, handleSubmit, onSubmit]);

  const onEnter = useCallback(async (e, { items, value }) => {
    if (!items.length && !!value) {
      const item = createItem(value, value);
      await setFieldValue(e.target.name, item);

      submitForm();
    }
    // console.log(p);
  }, [submitForm]);

  // to fix broken onEnter event for SearchForm in the same way as in MobileSearchPopover
  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, [submitForm]);

  const submitOnEnter = showUserCategories ? onKeyPress : undefined;

  return (
    <div className={cn(styles.container, className, { [styles.focused]: focused })}>

      <Form>
        <Row noGutters className="u-align-items-center">
          {
            isMobile && (
              <Col xs="auto" className="u-d-none-lg u-d-flex u-align-items-center">
                <Submit
                  type="button"
                  onClick={onSubmit}
                  className={styles['mobile-submit']}
                  icon="feather:search"
                  iconSize={18}
                  aria-label="Search"
                  color="none"
                />
              </Col>
            )
          }

          {
            (showUserCategories && !isMobile) && (
              <Col xs="auto" className={cn(styles.col, 'u-d-none u-d-block-lg')}>
                <SearchSelect
                  label="Contributors by"
                  component={Autocomplete}
                  // type="autocomplete"
                  select
                  // onChange={onChange}
                  name="category"
                  // select
                  items={userCategories}
                  // defaultHighlightedIndex={0}
                  selectedItem={activeUserCategory}
                  onSelect={onUserCategoryChange}
                  renderItem={item => (
                    <SearchSelectOption {...item} />
                  )}
                />
              </Col>
            )
          }

          {
            filters?.map((type, indx) => {
              const data = fields[type];

              if (!data) return null;

              const {
                component, mapToProps, mapValuesToProps, name, mobileWrapperTag, resetFields, enableSubmitOnEnter, onSelect: customOnSelect, onEnterSelect: customOnEnterSelect,
              } = data;
              const props = mapToProps ? mapToProps(restProps) : null;
              const valueProps = mapValuesToProps ? mapValuesToProps(values) : null;
              const selectable = (filters.length > 1) && !focused;
              const tag = (isMobile && mobileWrapperTag && standalone) ? mobileWrapperTag : 'div';

              return (
                <Col tag={tag} key={type} className={cn(styles.col, { [styles.selectable]: selectable })}>
                  <SearchSelect
                    label={data.label}
                    placeholder={data.placeholder}
                    component={component}
                    onChange={onChange}
                    name={name}
                    value={values[type]}
                    selectable={selectable}
                    inputProps={{
                      refCallback: refs?.current[indx],
                      onKeyPress: submitOnEnter,
                      name,
                    }}
                    onEnter={enableSubmitOnEnter ? onEnter : undefined}
                    onSelect={item => onSelect(item, indx, customOnSelect)}
                    showClearButton={!isMobile}
                    onClearButtonClick={onClearButtonClick}
                    resetFields={resetFields}
                    {...stateHandlers}
                    // items={items}
                    renderItem={data.renderItem}
                    onEnterSelect={item => onEnterSelect(item, indx, customOnEnterSelect)}
                    hideErrorMessage
                    // defaultHighlightedIndex={0}
                    {...props}
                    {...valueProps}
                  />
                </Col>
              );
            })
          }

          {
          !isMobile && (
            <Col xs="auto" className="u-d-none u-d-block-lg">
              <Submit
                onClick={onSubmit}
                type="button"
                className={styles.submit}
                icon="feather:search"
                iconSize={16}
                size="lg"
                {...submitProps}
              >
                {submitLabel}
              </Submit>
            </Col>
          )
        }
        </Row>
      </Form>
    </div>
  );
};

export default SearchGroup;
