import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { arrayOfShapes } from 'types';
import { stylesProxy } from 'helpers/css';
import Icon from 'oc-core-components/src/Icon';
import { useTranslation } from 'config/i18n';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { getV2RecentSearches } from 'v2/redux/meta/selectors';
import Button from 'oc-core-components/src/Button';

import stylesObj from './RecentSearchContainer.module.scss';

const styles = stylesProxy(stylesObj, 'RecentSearchContainer');

const RecentSearchContainer = ({
  recentSearches,
  className,
  theme = 'light',
}) => {
  const { t } = useTranslation('search');
  const isMobile = useMobile();
  const isDarkTheme = theme === 'dark';
  // const isLightTheme = theme === 'light';

  if (isMobile) {
    recentSearches.splice(3);
  }

  if (!recentSearches?.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.text, { [styles['text--dark']]: isDarkTheme })}>
        <Icon
          icon="feather:clock"
          size={14}
          className={cn(styles.text__icon, { [styles['text__icon--dark']]: isDarkTheme })}
        />
        {t('recent_searches')}
      </div>
      <div className={styles.inner}>
        {
          recentSearches?.map(({
            url, label,
            // eslint-disable-next-line react/no-array-index-key
          }, index) => (
            <Button
              key={`${url}__${label}__${index}`}
              color="none"
              className={cn(styles.tag, { [styles['tag--dark']]: isDarkTheme })}
              size="sm"
              href={url}
            >
              <Icon icon="feather:search" size={12} className={cn(styles.tag__icon, { [styles['tag__icon--dark']]: isDarkTheme })} />
              <span className={cn(styles.tag__inner, { [styles['tag__inner--dark']]: isDarkTheme })}>
                {label}
              </span>
            </Button>
          ))
        }
      </div>
    </div>
  );
};

RecentSearchContainer.propTypes = {
  recentSearches: arrayOfShapes(),
};

RecentSearchContainer.defaultProps = {
  recentSearches: [],
};

const mapStateToProps = state => ({
  recentSearches: getV2RecentSearches(state),
});

export default connect(
  mapStateToProps,
  null,
)(RecentSearchContainer);
