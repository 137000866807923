import { getFieldsDataByKind } from 'oc_components/src/pages/SearchNew/SearchGroup/fields';
import { useSelector } from 'react-redux';
import { selectV2ModelByIdProp } from 'v2/redux/selectors';
import { useMemo } from 'react';
import {
  getV2CurrentUserSchoolId,
  getV2CurrentUserSchoolName,
} from 'v2/redux/meta/selectors';
import { EMPTY_SCHOOL_IDS } from 'helpers/constants';

function useInitialData({
  kind,
  schoolId,
  courseId,
  query,
  tbQuery,
  category,
  userq,
  currentSubject,
}) {
  // const school = useSelector(state => selectV2ModelByIdProp.school(state, { schoolId }));

  const schoolById = useSelector(state => selectV2ModelByIdProp.school(state, { schoolId }));
  const currentUserSchoolId = useSelector(getV2CurrentUserSchoolId);
  const currentUserSchoolName = useSelector(getV2CurrentUserSchoolName);

  const currentUserSchool = useMemo(() => {
    const hasSchool = !!currentUserSchoolId && !EMPTY_SCHOOL_IDS.includes(currentUserSchoolId);

    return hasSchool ? {
      name: currentUserSchoolName,
      id: currentUserSchoolId,
    } : null;
  }, [currentUserSchoolName, currentUserSchoolId]);

  const school = schoolId ? schoolById : currentUserSchool;
  const course = useSelector(state => selectV2ModelByIdProp.course(state, { courseId }));

  const initialData = useMemo(() => {
    const fields = getFieldsDataByKind(kind, category, { allCategories: true });

    return Object.values(fields).reduce((acc, item) => {
      if (!item) {
        console.error('incorrect search fields', fields);

        return acc;
      }


      const { name, mapToInitialValue } = item;
      return {
        ...acc,
        [name]: mapToInitialValue ? mapToInitialValue({
          course, school, query, tbQuery, userq, subject: currentSubject,
        }) : null,
      };
    }, {});
  }, [kind, course, school, query, tbQuery, category, userq, currentSubject]);

  return initialData;
}

export default useInitialData;
