import React from 'react';
import { tagType } from 'types';
import {
  bool, string, node, oneOf,
} from 'prop-types';
import cn from 'classnames';

import { stylesProxy } from 'helpers/css';

import stylesObj from './ContentSection.module.scss';

const styles = stylesProxy(stylesObj, 'ContentSection');

export const Title = ({
  className,
  bordered,
  tag: Tag,
  ...restProps
}) => (
  <Tag className={cn(styles.title, className, { [styles['bordered-title']]: bordered })} {...restProps} />
);

Title.propTypes = {
  tag: tagType,
};

Title.defaultProps = {
  tag: 'h3',
};

export const Inner = ({
  className,
  noPaddingOnMobile,
  ...restProps
}) => (
  <div className={cn(styles.inner, { [styles['no-paddings']]: noPaddingOnMobile }, className)} {...restProps} />
);

Inner.propTypes = {
  noPaddingOnMobile: bool,
};

export const Wrapper = ({
  className,
  color,
  noBackground,
  wrapperTag: Tag,
  ...restProps
}) => (
  <Tag
    className={
    cn(
      styles.wrapper,
      className,
      (color && !noBackground) && styles[color],
      {
        [styles.bg]: !noBackground,
      },
    )
    }
    {...restProps}
  />
);

Wrapper.propTypes = {
  noBackground: bool,
  wrapperTag: tagType,
  color: oneOf(['dark', 'dark-dk']),
};

Wrapper.defaultProps = {
  wrapperTag: 'section',
};


const ContentSection = ({
  titleClassName,
  innerClassName,
  noInner,
  title,
  children,
  noPaddingOnMobile,
  borderTop,
  borderedTitle,
  className,
  ...restProps
}) => (

  <Wrapper
    className={cn({ [styles['border-top']]: borderTop }, className)}
    {...restProps}
  >
    {
      title && (
        <Title className={titleClassName} bordered={borderedTitle}>
          {title}
        </Title>
      )
    }

    {
      (React.Children.count(children) > 0) && !noInner && (
        <Inner className={innerClassName} noPaddingOnMobile={noPaddingOnMobile}>
          {children}
        </Inner>
      )
    }

    {
      noInner && <>{children}</>
    }


  </Wrapper>
);

ContentSection.propTypes = {
  titleClassName: string,
  innerClassName: string,
  noInner: bool,
  title: node,
  noPaddingOnMobile: bool,
  borderTop: bool,
};

export default ContentSection;
