import React from 'react';
import cn from 'classnames';
import { bool, node } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './MobileSearchSelect.module.scss';

const styles = stylesProxy(stylesObj, 'MobileSearchSelect');

const MobileSearchSelect = ({
  label,
  placeholder,
  className,
  items,
  renderItem,
  component: Component,
  inputProps,
  isSingle,
  wrapperClassName,
  ...restProps
}) => (
  <Component
    disableFiltering
    inputClassName={styles.input}
    inputFieldClassName={styles.input__field}
    wrapperClassName={wrapperClassName}
    placeholder={placeholder}
    className={cn(styles.container, className)}
    menuClassName={styles.menu}
    renderItem={renderItem}
    itemClassName={styles.dd__item}
    items={items}
    showClearButton
    disableLabel
    defaultHighlightedIndex={0}
    inputProps={{
      fieldContainerClassName: styles['field-wrapper'],
      ...inputProps,
    }}
    {...inputProps?.type === 'search' ? inputProps : {}}
    {...restProps}
  />
);

MobileSearchSelect.propTypes = {
  label: node,
  placeholder: node,
  active: bool,
  // type: oneOf(['school', 'course', 'question']),
};

export default MobileSearchSelect;
