const utils = require('./utils');

const { isFunction, IS_ONECLASS_PROD } = utils;

const createLogger = (logger, cb, devOnly) => (...args) => {
  if (!(devOnly && IS_ONECLASS_PROD)) {
    logger(...args);

    if (isFunction(cb)) {
      cb(...args);
    }
  }
};

/* eslint-disable no-console */
const log = createLogger(console.log);
const logError = createLogger(console.error);
const logWarning = createLogger(console.warn);
const logDev = createLogger(console.log, null, true);
const logErrorDev = createLogger(console.error, null, true);
const logWarningDev = createLogger(console.warn, null, true);
/* eslint-enable no-console */


// to debug values in expressions (log function which returns argument)
//
// @example
//   const someFunc = (a, b) => lg(a) * b;
const lg = variable => `a${console.log(variable)}` && variable;

module.exports = {
  log,
  logDev,
  logWarning,
  logWarningDev,
  logError,
  logErrorDev,
  lg,
};
