import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  string, bool, node, shape,
} from 'prop-types';
import { arrayOfShapes, LinkListType } from 'types';

import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import {
  UPLOAD_COURSE_MODAL,
  ADD_COURSES_MODAL,
} from 'oc-core-components/src/Modal/constants';

import {
  selectToolbarMenuItems,
  selectIsLoggedIn,
} from 'common/page/selectors';
import useAskQuestionModal from 'helpers/hooks/useAskQuestionModal';
import { trackAskQuestionButtonClick } from 'helpers/tracking';
import { isFunction } from 'helpers/utils';
import { getV2CurrentPage } from 'v2/redux/page/selectors';

// Required by selectToolbarMenuItems
import { withTranslation } from 'config/i18n';

import BottomNavbar from './BottomNavbar';

const BottomNavbarContainer = ({
  bottomToolbarBeforeContent,
  currentPage,
  isLoggedIn,
  items,
  toolbarHandlers,
}) => {
  const askQuestionModal = useAskQuestionModal();
  const modal = useModal();

  const handlers = useMemo(
    () => ({
      askQuestion: () => {
        trackAskQuestionButtonClick({
          trackId: 'mobile_nav_bar',
          url: window.location.href,
        });

        askQuestionModal();
      },
      logIn: modal.openLogin,
      signUp: modal.openSignup,
      addCourse: isLoggedIn ? () => modal.open(ADD_COURSES_MODAL) : null,
      upload: () => modal.open(UPLOAD_COURSE_MODAL),
      bookmarks: (e) => {
        if (!isLoggedIn) {
          e.preventDefault();
          modal.openLogin();
        }
      },
      yourQuestions: (e) => {
        if (!isLoggedIn) {
          e.preventDefault();
          modal.openLogin();
        }
      },
      myUnlocks: (e) => {
        if (!isLoggedIn) {
          e.preventDefault();
          modal.openLogin();
        }
      },
      ...toolbarHandlers,
    }),
    [askQuestionModal, currentPage, isLoggedIn, modal, toolbarHandlers],
  );

  const handleItemClick = (uid, e) => {
    const handler = handlers[uid];

    if (isFunction(handler)) {
      e?.preventDefault();
      handler(e);
    }
  };

  return (
    <BottomNavbar
      bottomToolbarBeforeContent={bottomToolbarBeforeContent}
      items={items}
      isLoggedIn={isLoggedIn}
      onItemClick={handleItemClick}
    />
  );
};

const mapStateToProps = (state, props) => ({
  items: selectToolbarMenuItems(state, props),
  isLoggedIn: selectIsLoggedIn(state, props),
  currentPage: getV2CurrentPage(state),
});

BottomNavbarContainer.propTypes = {
  bottomToolbarBeforeContent: node,
  currentPage: string,
  items: arrayOfShapes({
    label: string,
    url: string,
    items: LinkListType,
  }),
  isLoggedIn: bool,
  toolbarHandlers: shape(),
};

export default withTranslation()(
  connect(mapStateToProps)(BottomNavbarContainer),
);
