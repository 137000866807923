import React from 'react';
import {
  isFunction,
  isObject,
} from 'helpers/utils';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import SearchSelectOption from 'oc_components/src/pages/SearchNew/SearchSelectOption';
import { Field } from 'oc-core-components/src/Formik';
import { postCoursesV2, postSchoolsV2 } from 'common/api';
import { getCourseUploadLink } from 'common/course/utils';

import {
  QUERY_KEYS,
} from 'oc_components/src/pages/Search/utils';


import {
  itemToValue,
  createItem,
} from 'oc-core-components/src/Autocomplete/utils';

import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';


const fieldNames = {
  schoolId: QUERY_KEYS.schoolId,
  courseId: QUERY_KEYS.courseId,
  query: QUERY_KEYS.query,
  username: QUERY_KEYS.userq,
  tbQuery: QUERY_KEYS.tbQuery,
  subject: QUERY_KEYS.subject,
};

// TODO: i18n
const fields = {
  category: {
    // label: 'Contributors by',
    // component: Field,
    // items: [
    //   { label: 'Username', value: 'username' },
    //   { label: 'Course', value: 'course' },
    //   { label: 'Subject', value: 'subject' },
    // ],
    // resetFields: ['school_id', 'course_id', 'username', 'subject'],
    // mapToProps: () => ({
    //   type: 'autocomplete',
    // }),
    // mobileWrapperTag: 'h1',
    label: 'Contributors by',
    // placeholder: 'What’s your question',
    component: Field,
    name: fieldNames.query, // paramName
    mapToInitialValue: ({ query }) => query,
    mapToProps: () => ({
      type: 'autocomplete',
    }),
  },
  schoolId: {
    mobileWrapperTag: 'h1',
    label: 'School',
    placeholder: 'Enter school',
    component: Field,
    name: fieldNames.schoolId, // paramName
    mobileIcon: 'oc:university',
    resetFields: ['school_id', 'course_id'],
    mapToProps: () => ({
      countryId: '1', // TODO: remove
      type: 'schools',
      request: postSchoolsV2,
      clearOnMouseUp: true,
      // onSuggestionsLoad: s => console.log(s),
      // onBlur: () => console.log('123'),
    }),
    mapToInitialValue: ({ school }) => (school ? createItem(school?.name, school?.id?.toString()) : null),
    renderItem: ({
      label,
      state,
    }, _, { highlighted } = {}) => (
      <SearchSelectOption
        label={label}
        info={state}
        icon="oc:university"
        highlighted={highlighted}
      />
    ),
  },
  textbook: {
    mobileWrapperTag: 'h1',
    label: 'Textbook',
    placeholder: 'Enter ISBN, title, author',
    component: Field,
    resetFields: ['tb_query'],
    name: fieldNames.tbQuery, // paramName
    // mobileIcon: 'oc:university',
    // resetFields: ['school_id', 'course_id'],

    mapToInitialValue: ({ tbQuery }) => createItem(tbQuery, tbQuery),
    // TODO: TEMO FIX TEXTBOOK
    onSelect: (item, indx, { handleSubmit } = {}) => {
      if (item?.url) {
        window.location.assign(item?.url);
      }
    },
    enableSubmitOnEnter: true,
    // onEnter: async (e, { items, value, autocompleteProps: { selectItem } } = {}, { handleSubmit, onSelect, setFieldValue } = {}) => {
    //   if (!items.length && !!value) {
    //     const item = createItem(value, value);
    //     await setFieldValue('tb_query', item);
    //     setTimeout(() => handleSubmit(), 1000);
    //   }


    //   // if (item?.url) {
    //   //   window.location.assign(item?.url);
    //   // } else if (handleSubmit) {
    //   //   setTimeout(() => handleSubmit(), 500);
    //   // }
    // },
    mapToProps: () => ({
      type: 'textbooks',
      // clearOnMouseUp: true,
      // onSuggestionsLoad: s => console.log(s),
    }),
    // mapToInitialValue: ({ school }) => (school ? createItem(school?.name, school?.id?.toString()) : null),
  },
  // textbook: {
  //   mobileWrapperTag: 'h1',
  //   label: 'Textbook',
  //   placeholder: 'Enter ISBN, title, author',
  //   component: Field,
  //   name: fieldNames.query, // paramName
  //   mapToInitialValue: ({ query }) => query,
  //   mapToProps: () => ({
  //     type: 'text',
  //   }),
  //   // renderItem: item => (
  //   //   <SearchSelectOption {...item} />
  //   // ),
  // },
  courseId: {
    mobileWrapperTag: 'h2',
    label: 'Course',
    placeholder: 'Enter course code',
    component: Field,
    name: fieldNames.courseId, // paramName
    mobileIcon: 'oc:paperstack',
    mapToInitialValue: ({ course }) => (course ? createItem(course?.code, course?.id?.toString()) : null),
    resetFields: ['course_id'],
    mapToProps: () => ({
      type: 'courses',
      request: postCoursesV2,
      selectOnMouseUp: true,
      // onSuggestionsLoad: s => console.log(s),
      // onBlur: () => console.log('123'),
    }),
    mapValuesToProps: (values = {}) => {
      const schoolId = itemToValue(values[fieldNames.schoolId]);

      return ({ schoolId, disabled: !schoolId });
    },
    renderItem: ({
      label,
      departmentName,
      count,
      ntDocsCount,
      value,
      schoolId,
    }, _, { highlighted } = {}) => (
      <SearchSelectOption
        label={label}
        info={departmentName}
        meta={
          count ? (
            <>
              {count} documents {ntDocsCount > 0 && (
                <>
                  ({ntDocsCount} verified <Icon icon="oc:check-seal" stroke="none" size={12} />)
                </>
              )}
            </>
          ) : 'Upload a file'
        }
        icon="oc:paperstack"
        onClick={
          count ? undefined : (e) => {
            e.preventDefault();
            e.stopPropagation();
            const uploadLink = getCourseUploadLink({ courseId: value, schoolId });
            window.location.assign(uploadLink);
          }
        }
        highlighted={highlighted}
      />
    ),
  },
  question: {
    mobileWrapperTag: 'h1',
    label: 'Question',
    placeholder: 'What’s your question',
    component: Field,
    name: fieldNames.query, // paramName
    mapToInitialValue: ({ query }) => query || '',
    mapToProps: () => ({
      type: 'text',
    }),
    // renderItem: item => (
    //   <SearchSelectOption {...item} />
    // ),
  },
  blog: {
    mobileWrapperTag: 'h1',
    label: 'Blogs', // paramName
    placeholder: 'Search blogs',
    component: Field,
    name: fieldNames.query,
    mapToInitialValue: ({ query }) => query || '',
    mapToProps: () => ({
      type: 'text',
    }),
    // renderItem: item => (
    //   <SearchSelectOption {...item} />
    // ),
  },
  username: {
    mobileWrapperTag: 'h1',
    label: 'Username',
    placeholder: 'Search username',
    component: Field,
    mobileIcon: 'feather:user',
    name: fieldNames.username, // paramName
    mapToInitialValue: ({ userq }) => userq || '',
    mapToProps: () => ({
      type: 'text',
    }),
    // renderItem: item => (
    //   <SearchSelectOption {...item} />
    // ),
  },
  subject: {
    mobileWrapperTag: 'h1',
    label: 'Subject',
    placeholder: 'Search subject',
    component: Field,
    mobileIcon: 'feather:layers',
    name: fieldNames.subject, // paramName
    mapToInitialValue: ({ subject }) => subject,
    mapToProps: ({ userSubjects }) => ({
      type: 'autocomplete',
      items: userSubjects,
      disableFiltering: false,
    }),
    renderItem: item => (
      <SearchSelectOption {...item} />
    ),
  },
};

export const userFields = {
  username: ['username'],
  course: ['schoolId', 'courseId'],
  subject: ['subject'],
};

export const allUserFields = uniq(flatten(Object.values(userFields)));

export const fieldsByKind = {
  blog: ['blog'],
  hw: ['question'],
  ts: ['textbook'],
  user: (category, { allCategories } = {}) => {
    if (allCategories) {
      return allUserFields;
    }

    return userFields[category] || userFields.course;
  },
  default: ['schoolId', 'courseId'],
};


export const getFieldsByKind = (kind, category, props = {}) => {
  const result = fieldsByKind[kind];

  if (isFunction(result)) {
    return result(category, props);
  }

  return result || fieldsByKind.default;
};

export const getFieldsDataByKind = (kind, category, props) => {
  const fieldsNames = getFieldsByKind(kind, category, props);

  return fieldsNames.reduce((acc, name) => ({
    ...acc,
    [name]: fields[name],
  }), {});
};

export default fields;
