import React from 'react';
import MobileSearchPopover from 'oc_components/src/pages/SearchNew/MobileSearchPopover';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import Icon from 'oc-core-components/src/Icon';
import useSearch from 'oc_components/src/pages/Search/useSearch';
import {
  string, number, func, shape, oneOfType,
} from 'prop-types';

const NavbarMobileSearchButton = ({
  className,
  iconClassName,
  toggleClassName,
  iconSize,
  label,
}) => {
  const isMobile = useMobile();

  const {
    // filters,
    // setFilter,

    // showUserCategories,
    // userCategories,
    // activeUserCategory,
    // onUserCategoryChange,
    sideFiltersData,
    fallbackTopics,
  } = useSearch({});

  const userSubjects = sideFiltersData?.topics?.items || fallbackTopics;

  const toggle = (
    <div className={toggleClassName}>
      <Icon icon="feather:search" size={iconSize} className={iconClassName} />

      {label && label}
    </div>
  );

  return (isMobile ? (
    <MobileSearchPopover
      toggle={toggle}
      // onSubmit={submitHandler}
      filters={['schoolId', 'courseId']}
      // initialData={initialData}
      className={className || 'u-d-block u-w-100'}
      toggleClassName="u-d-block u-w-100"
      userSubjects={userSubjects}
    />
  ) : null);
};

NavbarMobileSearchButton.propTypes = {
  iconClassName: string,
  label: oneOfType([string, shape(), func]),
  toggleClassName: string,
  iconSize: number,
};

export default NavbarMobileSearchButton;
