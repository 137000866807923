import React, {
  useRef, createRef, useEffect, useCallback, useMemo, useState,
} from 'react';
import cn from 'classnames';
import Dropdown from 'oc-core-components/src/Dropdown';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import Autocomplete from 'oc-core-components/src/Autocomplete';
import SearchSelectOption from 'oc_components/src/pages/SearchNew/SearchSelectOption';
import fields, { getFieldsByKind } from 'oc_components/src/pages/SearchNew/SearchGroup/fields';
import useInitialData from 'oc_components/src/pages/SearchNew/SearchGroup/useInitialData';
import useResetDependencies from 'oc_components/src/pages/SearchNew/SearchGroup/useResetDependencies';
import { SearchFormEnhancer } from 'oc_components/src/pages/SearchNew/SearchForm';
import { Submit } from 'oc-core-components/src/Formik';
import { Form } from 'formik';
import useNavbarSearch from 'shared/helpers/hooks/useNavbarSearch';
import NavbarSearchTabs from 'shared/oc-core-components/src/components/NavbarV2/NavbarSearchTabs';
import useSearch from 'oc_components/src/pages/Search/useSearch';
import RecentSearchContainer from 'components/pages/Search/RecentSearchContainer';
import usePrevious from 'helpers/hooks/usePrevious';
import { createItem } from 'oc-core-components/src/Autocomplete/utils';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import MobileSearchSelect from './components/MobileSearchSelect';
import stylesObj from './MobileSearchPopover.module.scss';

const styles = stylesProxy(stylesObj, 'MobileSearchPopover');

const SELECT_TYPES = [
  'courses', 'schools',
];

const MobileSearchPopoverInner = SearchFormEnhancer(({
  filters,
  setActiveNavbarSearchTabId,
  activeNavbarSearchTabId,
  navbarSearchTabs,
  values,
  setFieldValue,
  setFieldTouched,
  setErrors,
  closeHandler,
  submitForm,
  initialData,
  kind,
  props: restProps,

  userCategories,
  activeUserCategory,
  onUserCategoryChange,
}) => {
  const refs = useMemo(() => ({ current: new Array(filters?.length).fill(null).map(() => createRef()) }), [filters]);
  const previousKind = usePrevious(kind);
  const kindIsChanged = kind !== previousKind;
  const [isRecentSearchVisible, setRecentSearchVisibility] = useState(true);
  const {
    addErrorNotification,
  } = useNotifications();

  const onSelect = useCallback((item, indx, cb) => {
    const type = refs?.current[indx]?.current?.type;

    if (!type) return;

    setTimeout(() => {
      if (type !== 'search') {
        refs?.current[indx + 1]?.current?.focus();
      } else if (cb) {
        cb(item);
      } else {
        submitForm();
      }
    }, 100);
  }, [refs, submitForm]);

  const onSuggestionsLoad = useCallback(({ items, val } = {}) => {
    if (items?.length > 0) {
      // edge case (to avoid strange effect on MobileSearchPopover on tabChange)
      if (items?.length === 1 && items?.[0]?.label === val) {
        return;
      }

      return setRecentSearchVisibility(false);
    }
    return setRecentSearchVisibility(true);
  }, []);

  const onSelectBlur = useCallback(() => {
    setRecentSearchVisibility(true);
  }, []);

  useResetDependencies({
    values,
    filters,
    setFieldValue,
  });

  useEffect(() => {
    const refsArr = refs?.current;

    if (!refsArr?.length) {
      return;
    }

    const firstRef = refsArr[0]?.current;
    if (!firstRef) return;

    const currentInput = refsArr.find(el => !initialData[el?.current?.name]);

    if (currentInput?.current) {
      currentInput?.current.focus();
    }
  }, [initialData, kindIsChanged, refs]);

  const onEnter = useCallback(async (e, { items, value }) => {
    if (!items.length && !!value) {
      const item = createItem(value, value);
      await setFieldValue(e.target.name, item);

      submitForm();
    }
    // console.log(p);
  }, [submitForm]);


  const showUserCategories = activeNavbarSearchTabId === 'navUsers';
  const filtersLength = (filters?.length + showUserCategories) || 0;

  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      // Maggie: about this one do u think we can disable search button if nothing is entered?
      if (!values.userq) {
        // setFieldTouched('userq', true);
        // setErrors({ userq: 'This field is required' });
        // addErrorNotification("Username can't be blank");
        return;
      } else {
        submitForm();
      }
    }
  }, [submitForm, values, setErrors, setFieldTouched]);

  const submitOnEnter = showUserCategories ? onKeyPress : undefined;


  return (
    <>

      <Form className={styles.container} action=".">
        <div className={cn(styles.top, styles.section)}>
          <Link className={styles.close} onClick={closeHandler}>
            <Icon icon="feather:chevron-left" />
          </Link>
        </div>

        <div className={styles.tabs}>
          <div className={cn(styles.section)}>
            <NavbarSearchTabs
              tabs={navbarSearchTabs}
              onTabClick={setActiveNavbarSearchTabId}
              // // className="u-mb-m"
              defaultActiveTab={activeNavbarSearchTabId}
              hideArrows
              className={styles.tabs_row}
            />
          </div>
        </div>

        <div className={styles.inner}>
          <div className={styles.section}>
            <div className={styles.form}>
              {
                showUserCategories && (
                  <div
                    className={styles.form__field}
                  >
                    <MobileSearchSelect
                      placeholder="Contributors by"
                      component={Autocomplete}
                      name="category"
                      selectedItem={activeUserCategory}
                      renderItem={item => (
                        <SearchSelectOption {...item} />
                      )}
                      select
                      inputWrapperClassName={styles.select__wrapper}
                      items={userCategories}
                      disableMenuStyles
                      menuWrapper="div"
                      onSelect={onUserCategoryChange}
                      onEnterSelect={onUserCategoryChange}
                      showClearButton={false}
                      inputProps={{
                        // refCallback: refs?.current[indx],
                        icon: 'feather:search',
                        iconPosition: 'first',
                        name: 'category',
                      }}
                      // onEnter={enableSubmitOnEnter ? onEnter : undefined}
                      cssSize="lg"
                      menuPortalSelector="#search_menu"
                    />
                  </div>
                )
              }

              {
                filters?.map((type, indx) => {
                  const data = fields[type];

                  if (!data) return null;

                  const {
                    component, mapToProps, mapValuesToProps, name, mobileIcon, onSelect: customOnSelect, enableSubmitOnEnter,
                  } = data;
                  const filterProps = mapToProps ? mapToProps(restProps) : null;
                  const valueProps = mapValuesToProps ? mapValuesToProps(values) : null;

                  return (
                    <div
                      key={type}
                      className={cn({
                        [styles.form__field]: (filtersLength > 1),
                        [styles.single]: filtersLength === 1 && filterProps?.type !== 'text' && filterProps?.type !== 'search',
                      })}
                    >
                      <MobileSearchSelect
                        placeholder={data.placeholder}
                        className={styles.inner}
                        component={component}
                        name={name}
                        isSingle={filtersLength < 2}
                        value={values[type]}
                        renderItem={data.renderItem}
                        disableMenuStyles
                        menuWrapper="div"
                        onSelect={item => onSelect(item, indx, customOnSelect)}
                        onEnterSelect={item => onSelect(item, indx, customOnSelect)}
                        inputProps={{
                          inputFieldClassName: 'u-w-100',
                          refCallback: refs?.current[indx],
                          icon: mobileIcon,
                          iconStroke: mobileIcon?.startsWith('feather') ? undefined : 'none',
                          iconPosition: 'first',
                          type: indx === (filters.length - 1) ? 'search' : 'text',
                          onSuggestionsLoad: SELECT_TYPES.includes(filterProps?.type) ? onSuggestionsLoad : undefined,
                          onBlur: SELECT_TYPES.includes(filterProps?.type) ? onSelectBlur : undefined,
                          // TODO: refactor
                          // NOTE: temp solution to fix the following issue:
                          //   the form is ignoring onEnter events because of upper MobileSearchInput
                          //   if remove upper MobileSearchSelect with <input name="category" /> from form markup - onEnter events are working correctly ¯\_(ツ)_/¯
                          onKeyPress: submitOnEnter,
                          hideErrorMessage: true,
                          // errorClassName: 'u-font-size-xs u-ml-s u-mb-xxs',
                          name,
                        }}
                        onEnter={enableSubmitOnEnter ? onEnter : undefined}
                        cssSize="lg"
                        menuPortalSelector="#search_menu"
                        {...filterProps}
                        {...valueProps}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>

          {
            isRecentSearchVisible && (
              <div className={cn(styles.section, 'u-mb-s')}>
                <RecentSearchContainer theme="dark" />
              </div>
            )
          }

          <div className={styles.content}>
            <div id="search_menu" className={styles.options} />
          </div>
        </div>
        {/*
        <div className={styles.footer}>
          <Submit className="u-px-m u-py-xxs" color="dark">
            Search
          </Submit>
        </div> */}


      </Form>
    </>
  );
});

const MobileSearchPopover = ({
  toggle,
  withRedirect = true,
  redirectParams,
  // onSubmit,
  // initialData,
  ...restProps
}) => {
  const {
    // isSearchButtonHidden,
    // isSearchDropdownOpen,
    // closeSearchDropdown,
    // openSearchDropdown,

    setActiveNavbarSearchTabId,
    activeNavbarSearchTab,
    activeNavbarSearchTabId,
    // activeProductCategory,
    navbarSearchTabs,
  } = useNavbarSearch();

  const searchData = activeNavbarSearchTab?.searchData;
  const {
    filters: searchFilters,
    setFilter,
    activeUserCategory,
    userCategories,
    onUserCategoryChange,
  } = useSearch(searchData);

  const {
    kind,
  } = searchFilters || {};

  const initialData = useInitialData(searchFilters);

  const categoryValue = activeUserCategory?.value;

  const filters = getFieldsByKind(kind, categoryValue);

  const onSubmit = useCallback(
    values => setFilter(values, null, withRedirect, (redirectParams || { kind: kind === 'default' ? undefined : kind })),
    [withRedirect, setFilter, redirectParams, kind],
  );

  return (
    <Dropdown
      trigger="click"
      disableMenuStyles
      portal
      toggle={toggle}
      {...restProps}
    >
      {
          ({ close }) => (
            <MobileSearchPopoverInner
              closeHandler={close}
              filters={filters}
              props={restProps}
              initialData={initialData}
              setActiveNavbarSearchTabId={setActiveNavbarSearchTabId}
              activeNavbarSearchTabId={activeNavbarSearchTabId}
              navbarSearchTabs={navbarSearchTabs}
              kind={kind}
              onSubmit={(v) => {
                if (onSubmit) {
                  onSubmit(v);
                }
                close();
              }}
              activeUserCategory={activeUserCategory}
              userCategories={userCategories}
              onUserCategoryChange={onUserCategoryChange}
            />
          )
        }
    </Dropdown>
  );
};

export default MobileSearchPopover;
