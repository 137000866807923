import dynamic from 'shared/helpers/dynamic';
import keyBy from 'lodash/keyBy';
import { isNil, shortenLargeNumber } from 'helpers/utils';
import { logErrorDev } from 'helpers/loggers';
import {
  BLOG_ARTICLE_MODEL_TYPE, QUESTION_MODEL_TYPE, DOCUMENT_MODEL_TYPE, USER_MODEL_TYPE,
} from 'v2/redux/constants';
import { isObject } from 'shared/helpers/utils';

// const dynamicProp = { ssr: true };

// const c = dynamic(
//   () => import(
//       /* webpackChunkName: "BlogArticlesSection" */ './_sections/BlogArticlesSection'
//     ),
//   dynamicProp,
// ),


export const QUERY_KEYS = {
  kind: 'kind',
  order: 'order',
  courseId: 'course_id',
  schoolId: 'school_id',
  query: 'query',
  tbQuery: 'tb_query',
  lecturNo: 'lecture_no',
  term: 'semester',
  examType: 'exam_type',
  status: 'status',
  type: 'type',
  subject: 'subject',
  userq: 'userq',
};

// ================= SEARCH CATEGORIES =================

const searchCategories = [
  {
    uid: 'studyGuides',
    paramValue: 'sg',
    materialType: 'study-guides',
    fullKind: 'study-guide', // generate upload url
    modelType: DOCUMENT_MODEL_TYPE,
    labelI18n: 'search:categories.studyGuides',
  },
  {
    uid: 'classNotes',
    paramValue: 'cn',
    materialType: 'class-notes',
    fullKind: 'class-note',
    modelType: DOCUMENT_MODEL_TYPE,
    labelI18n: 'search:categories.classNotes',
  },
  {
    uid: 'textbookNotes',
    paramValue: 'tn',
    materialType: 'textbook-notes',
    fullKind: 'textbook-note',
    modelType: DOCUMENT_MODEL_TYPE,
    labelI18n: 'search:categories.textbookNotes',
  },
  {
    uid: 'questions',
    paramValue: 'hw',
    modelType: QUESTION_MODEL_TYPE,
    labelI18n: 'search:categories.homeworkHelp',
  },
  {
    uid: 'users',
    paramValue: 'user',
    modelType: USER_MODEL_TYPE,
    labelI18n: 'search:categories.users',
  },
  {
    uid: 'textbooks',
    paramValue: 'ts',
    materialType: 'textbooks',
    fullKind: 'textbooks', // generate upload url
    modelType: DOCUMENT_MODEL_TYPE,
    labelI18n: 'search:categories.textbooks',
  },
  {
    uid: 'blogArticles',
    paramValue: 'blog',
    modelType: BLOG_ARTICLE_MODEL_TYPE,
    labelI18n: 'search:categories.blogArticles',
  },

  // {
  //   uid: 'boosterClasses',
  //   paramValue: 'bc',
  // params: { kind: 'bc' }, // TODO: deprecated
  //   labelI18n: 'categories.boosterClasses',
  //   Component: dynamic(
  //     () => import(
  //         /* webpackChunkName: "BoosterClassesSection" */ './_sections/BoosterClassesSection'
  //       ),
  //     dynamicProp,
  //   ),
  // },
  // {
  //   uid: 'skills',
  //   paramValue: 'skill',
  //    params: { kind: 'skill' }, // TODO: deprecated
  //   labelI18n: 'categories.skills',
  //   Component: dynamic(
  //     () => import(
  //         /* webpackChunkName: "SkillsSection" */ './_sections/SkillsSection'
  //       ),
  //     dynamicProp,
  //   ),
  // },


  // {
  //   uid: 'streamVideos',
  //   paramValue: 'livestream',
  //   params: { kind: 'livestream' }, // TODO: deprecated
  //   labelI18n: 'categories.streamVideos',
  //   Component: dynamic(
  //     () => import(
  //         /* webpackChunkName: "StreamVideosSection" */ './_sections/StreamVideosSection'
  //       ),
  //     dynamicProp,
  //   ),
  // },

];

export const allCategory = Object.freeze({
  uid: 'all',
  paramValue: undefined,
  materialType: 'all-materials',
  modelType: DOCUMENT_MODEL_TYPE,
  fullKind: 'all-materials',
  labelI18n: 'search:categories.all', // sidebar label
  titleI18n: 'search:all_materials', // page title label (use labelI18n, if not given)
});

export const categoriesByKind = Object.freeze(keyBy(searchCategories, o => o?.paramValue));
export const categoriesByUid = Object.freeze(keyBy(searchCategories, 'uid'));
export const categoriesUids = Object.keys(categoriesByUid);
export const categoriesKinds = Object.keys(categoriesByKind);
export const kindsByMaterialType = Object.freeze(
  ['studyGuides', 'classNotes', 'textbookNotes'].reduce((acc, uid) => ({
    ...acc,
    [categoriesByUid[uid]?.materialType]: categoriesByUid[uid]?.paramValue,
  }), {}),
);

export const defaultCategory = allCategory;

export const getUrlParamsByUid = (uid) => {
  try {
    if (!categoriesUids.includes(uid)) {
      throw new Error('incorrect cateogry uid');
    }

    const kind = categoriesByUid[uid]?.paramValue;

    return kind ? { [QUERY_KEYS.kind]: kind } : null;
  } catch (e) {
    console.error(e);
  }
};

export const isKindValid = value => isNil(value) || (defaultCategory.paramValue === value) || categoriesKinds.includes(value);

export const getKindByMaterialType = (mt) => {
  if (!mt) return undefined;

  return [...searchCategories, defaultCategory].find(({ materialType }) => materialType === mt)?.paramValue;
};

export const getUrlParamsByMaterialType = (mt) => {
  const kind = getKindByMaterialType(mt);

  return kind ? { [QUERY_KEYS.kind]: kind } : null;
};

export const isDocumentKind = kind => (
  isKindValid(kind)
    && ![categoriesByUid.questions.paramValue,
      categoriesByUid.blogArticles.paramValue,
      categoriesByUid.textbooks.paramValue,
      categoriesByUid.users?.paramValue,
    ].includes(kind)
);

export const isUserKind = kind => (
  isKindValid(kind) && (categoriesByUid.users.paramValue === kind)
);

export const isHWKind = kind => kind === categoriesByUid.questions.paramValue;


export const fallbackKind = (value) => {
  if (isKindValid(value)) {
    return value;
  }

  logErrorDev('Incorrect kind:', value);

  return defaultCategory.paramValue;
};

export const categoriesMenu = [
  defaultCategory,
  ...searchCategories,
];

export const getTotalCounter = (counters) => {
  if (!isObject(counters)) return 0;

  return categoriesUids
    .filter(uid => !['questions', 'blogArticles', 'textbooks', 'users'].includes(uid))
    .reduce((acc, uid) => (acc + (counters[uid] || 0)), 0);
};

export const formatCount = (count) => {
  const countNum = parseInt(count, 10);

  if (Number.isNaN(countNum)) return undefined;

  return (countNum > 1000 ? shortenLargeNumber(countNum, 1, true) : countNum).toString();
};

export const getCounterByKind = (kind, counters, format) => {
  if (!isObject(counters)) {
    return 0;
  }

  if (!kind) {
    return getTotalCounter(counters);
  }

  const uid = categoriesByKind[kind]?.uid;

  let res = counters[uid] || 0;

  if (format) {
    res = formatCount(res);
  }

  return res;
};

// ================= USER CATEGORIES =================

export const USER_CATEGORIES = Object.freeze({
  username: { label: 'Username', value: 'username' },
  course: { label: 'Course', value: 'course' },
  subject: { label: 'Subject', value: 'subject' },
});

export const MOBILE_USER_CATEGORIES = Object.freeze({
  username: { label: 'By username', value: 'username' },
  course: { label: 'By course', value: 'course' },
  subject: { label: 'By subject', value: 'subject' },
});

// export const defaultUserCategory = USER_CATEGORIES.username;

// export const isUserCategoryValid = value => isNil(value) || !!Object.values(USER_CATEGORIES).find(o => o.value === value);

// export const fallbackUserCategory = (cat) => {
//   const isValid = isUserCategoryValid(cat?.value);

//   if (!isValid) {
//     logErrorDev('Incorrect user category:', cat?.value);
//   }

//   if (!isValid || !cat) {
//     return fallbackUserCategory;
//   }

//   return cat;
// };


// ================= SORTING =================

export const ORDER_TYPES = Object.freeze({
  // suggested: { i18n: 'common:suggested', value: 'suggested' },
  // mostPopular: { i18n: 'common:most_popular', value: 'popular-alltime' },
  mostRecent: { i18n: 'common:most_relevant', value: 'most-recent' },
  trending: { i18n: 'common:trending', value: 'trending' },
  mostHelped: { i18n: 'common:most_helped', value: 'most-helped' },
  mostDocuments: { i18n: 'common:most_documents', value: 'most-documents' },
  mostAnswers: { i18n: 'common:most_answers', value: 'most-answers' },
});

export const defaultOrderObject = ORDER_TYPES.mostRecent;

export const isOrderValueValid = value => isNil(value) || !!Object.values(ORDER_TYPES).find(o => o.value === value);

export const fallbackOrderValue = (value) => {
  const defaultValue = defaultOrderObject.value;
  const isValid = isOrderValueValid(value);

  if (!isValid) {
    logErrorDev('Incorrect order:', value);
  }

  if (!isValid || !value) {
    return defaultValue;
  }

  return value;
};

// ================= RESENT SEARCHES =================

export const MAX_SEARCH_HISTORIES = 5;


// ================= FILTERS =================

export const filtersData = {
  ntCourses: {
    titleI18n: 'common:verified_notes',
    // paramKey: QUERY_KEYS.term,
    paramKey: 'nt_courses',
    icon: 'oc:check-seal',
    iconTooltipText: 'Verified notes are comprehensive and high-quality content covering the entire course. They are created by approved Note Takers who have taken the course.',
    // validate(v) { return Object.keys(this.itemsI18n).includes(v); },
    // itemsI18n: {
    //   winter: 'common:winter',
    //   spring: 'common:spring',
    //   summer: 'common:summer',
    //   fall: 'common:fall',
    // },
  },
  term: {
    titleI18n: 'common:semester',
    paramKey: QUERY_KEYS.term,
    // validate(v) { return Object.keys(this.itemsI18n).includes(v); },
    // itemsI18n: {
    //   winter: 'common:winter',
    //   spring: 'common:spring',
    //   summer: 'common:summer',
    //   fall: 'common:fall',
    // },
  },
  examType: {
    titleI18n: 'search:exams',
    paramKey: QUERY_KEYS.examType,
    // validate(v) { return Object.keys(this.itemsI18n).includes(v); },
    // itemsI18n: {
    //   quiz: 'search:quiz',
    //   final: 'search:final_exam',
    //   midterm: 'search:midterm_exam',
    // },
  },
  lectureNo: {
    titleI18n: 'search:lectures',
    filterI18n: 'search:lecture',
    paramKey: QUERY_KEYS.lecturNo,
    maxShowCount: 10,
    // validate(v) { return !Number.isNaN(parseInt(v, 10)); },
    itemsI18n: (key, t) => t('search:lecture_with_num', { number: key }),
  },
  status: {
    titleI18n: 'search:status',
    paramKey: QUERY_KEYS.status,
  },
  type: {
    titleI18n: 'search:contributions',
    paramKey: QUERY_KEYS.type,
  },
  topics: {
    titleI18n: 'search:subjects',
    paramKey: QUERY_KEYS.subject,
  },
};

// export const fallbackFilter = (type, v) => (filtersData[type]?.validate(v) ? v : undefined);
