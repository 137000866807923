import cn from 'classnames';
import { stylesProxy } from 'helpers/css';
import { getDynamicImportProps } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import dynamic from 'next/dynamic';
import Button from 'oc-core-components/src/Button';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';
import { bool, func, node, string } from 'prop-types';
import React, { PureComponent } from 'react';
import NavbarMobileSearchButton from 'oc-core-components/src/components/NavbarV2/NavbarMobileSearchButton';
import stylesObj from './BottomNavbar.module.scss';
import ampStylesObj from './BottomNavbar.module.scss?amp&type=resolve';
import { useTranslation } from 'config/i18n';

const s = stylesProxy(stylesObj, 'BottomNavbar');

const dynamicProps = getDynamicImportProps();

const MessageCounter = dynamic(
  () =>
    import(
      /* webpackChunkName: "chunk_MessageCounter" */ 'oc-core-components/src/Notifications/NotificationsCounterContainer'
    ),
  dynamicProps
);

const BottomNavbarItem = ({
  icon,
  iconStroke,
  label,
  href,
  isOpen,
  onClick,
  openMenu,
  children,
  linkClassName,
  labelClassName,
  withNotifications,
  round,
  nofollow,
  styles,
  uid,
  title,
  ...restProps
}) => {
  const { t } = useTranslation('navbar');

  return (
    <Col className={styles.item}>
      {!openMenu && (uid !== 'search') && (
        <Link
          nofollow={nofollow}
          href={href}
          className={cn(styles.link, linkClassName)}
          onClick={onClick}
          {...restProps}
        >
          <Icon
            className={cn(styles.icon, round && styles.round)}
            icon={icon}
            size={20}
            stroke={iconStroke}
          >
            {withNotifications && (
              <MessageCounter sticked className={styles.count}/>
            )}
          </Icon>
          <div className={cn(styles.label, labelClassName)}>{label}</div>
        </Link>
      )}

      {
        uid === 'search' && (
          <div className={cn(styles.link, linkClassName)}>
            <NavbarMobileSearchButton
              className={cn(styles.icon, styles.search_item, round && styles.round)}
              toggleClassName={styles.search_item_toggle}
              iconClassName={styles.search_item_icon}
              iconSize={20}
              label={
                <div className={cn(styles.label, labelClassName)}>{label}</div>
              }
            />

          </div>
        )
      }

      {isOpen && React.Children.count(children) > 0 && (
        <div className={styles.dd}>
          {title && <div className={styles.dd__title}>{title}</div>}
          <ul className={styles.dd__section}>{children}</ul>

          <div className={styles['dd__close-container']}>

            <Button
              className={styles.dd__close}
              color="dark"
              icon="feather:x"
              iconClassName="u-mx-0"
              iconSize={20}
              onClick={onClick}
            />
          </div>
        </div>
      )}
    </Col>
  )
}

BottomNavbarItem.propTypes = {
  icon: string,
  iconStroke: string,
  label: string,
  children: node,
  isOpen: bool,
  onClick: func,
  href: string,
  withNotifications: bool,
  linkClassName: string,
  labelClassName: string,
  round: bool,
  uid: string,
};

export default withAmpStyles(BottomNavbarItem, s, stylesObj, ampStylesObj);
